import React from 'react'
import EmbedVideoFrame from '../components/EmbedVideoFrame'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"


const Amazon = ({ location }) => {
  return (
    <Layout location={location}>
        <section id='watchVideoClass' class="w-11/12 2xl:max-w-7xl mx-auto mb-5 rounded-xl flex place-content-center relative bg-purple-30 pt-9 xl:py-0 overflow-hidden z-0 px-3 lg:px-0 mb-10">
                <div className="w-full grid lg:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
                    <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-20 xl:pb-20 no-br">
                            <div className="my-2 flex justify-center items-center">
                                <h2 className="pl-1 text-center lg:text-left heroTagStyle">AMAZON BUSINESS</h2>
                            </div>
                            <h1 className = "lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left text-indigo-100">Amazon, now integrated within Vantage Circle.</h1>
                            <p className="my-8 text-indigo-100 text-center lg:text-left w-full" style= {{ lineHeight: '2rem' }}>We have partnered with Amazon business to bring over 10 million reward redemption options for your employees right into the heart of Vantage Circle’s Reward Catalog.<br/><br/>
                                Let your employees choose the reward they actually want.</p>
                            <div className="xl:grid grid-cols-2 justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-10/12 2xl:w-full cursor-pointer my-8 z-10">
                                <a className="vc-new-orange-btn-rounded-full text-white lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#form">Get Started</a>
                                {/* <button className="youtube-video-btn vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle">Watch video</button> */}
                            </div>      
                    </div>   
                    <div className="lg:absolute md:-bottom-64 md:scale-90 lg:scale-75 lg:transform xl:scale-100 md:right-5 lg:-bottom-20 lg:-right-24 xl:-bottom-9 xl:right-5 2xl:right-3 flex justify-center items-center lg:h-full mt-6 pt-4 xl:mt-0 lg:ml-3">
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_700/q_auto/gatsbycms/uploads/2023/02/amazon-business.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="680" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/amazon-business.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/amazon-business.webp" alt="Vantage Rewards" width="500" />
                        </picture>
                    </div>
                </div>
        </section>
        <section className='w-full flex place-content-center overflow-hidden'>
            <div className='xl:max-w-7xl'>
                <div className=''>
                    <EmbedVideoFrame 
                        videoLink={"https://www.youtube.com/embed/lMrM3gOJcyQ"} 
                        title={"Watch the video to understand how it works."} 
                        thumbnail = {"https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/amazon-video-thumbnail.webp"}
                    />
                </div>
            </div>
        </section>

        <section className='w-full flex place-content-center py-10 xl:py-20 bg-gray-100 px-5 xl:px-0'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-heading text-center mb-6 max-w-5xl mx-auto'>Supercharge your rewards program with our Amazon Store</h2>
                <p className='text-center max-w-4xl my-5 mx-auto text-gray-500' style= {{ lineHeight: '2rem' }}>Make your employees fall in love with your rewards program by giving them the flexibility to shop on Amazon with reward points! Amazing right?</p>
                <div className='md:grid md:grid-cols-2 xl:grid-cols-4 gap-12 mt-2 md:mt-10 lg:mt-20'>
                    <div className='grid place-content-center my-5 md:m-0'>
                        <div className='flex place-content-center'>
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/amazon-million-of-product.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="100" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-million-of-product.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/amazon-million-of-product.webp" alt="Vantage Rewards" width="100" />
                        </picture>
                        </div>
                        <p className='font-bold text-center pt-3 text-gray-500 m-2'>Millions of Products</p>
                        <p className='text-base text-center'>Real-time Amazon catalog with offers and discounts</p>
                    </div>
                    <div className='grid place-content-center my-5 md:m-0'>
                        <div className='flex place-content-center'>
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/amazon-free-shipping.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="100" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-free-shipping.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload//w_100/q_auto/gatsbycms/uploads/2023/10/amazon-free-shipping.webp" alt="Vantage Rewards" width="100" />
                        </picture>
                        </div>
                        <p className='font-bold text-center pt-3 text-gray-500 m-2'>Free shipping with Prime</p>
                        <p className='text-base text-center'>Zero delivery charges and assured last-mile delivery</p>
                    </div>
                    <div className='grid place-content-center my-5 md:m-0'>
                        <div className='flex place-content-center'>
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/amazon-save-time.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="100" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-save-time.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload//w_100/q_auto/gatsbycms/uploads/2023/10/amazon-save-time.webp" alt="Vantage Rewards" width="100" />
                        </picture>
                        </div>
                        <p className='font-bold text-center pt-3 text-gray-500 m-2'>Reduced transit time</p>
                        <p className='text-base text-center'>Express delivery with Amazon Prime network anywhere in the USA</p>
                    </div>
                    <div className='grid place-content-center my-5 md:m-0'>
                        <div className='flex place-content-center'>
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/amazon-service-year-catalog.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="100" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_100/q_auto/gatsbycms/uploads/2023/10/amazon-service-year-catalog.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload//w_100/q_auto/gatsbycms/uploads/2023/10/amazon-service-year-catalog.webp" alt="Vantage Rewards" width="100" />
                        </picture>
                        </div>
                        <p className='font-bold text-center pt-3 text-gray-500 m-2'>Service Awards catalog</p>
                        <p className='text-base text-center'>Create your own Service Awards catalog fulfilled by Amazon</p>
                    </div>
                </div>
                
            </div>
        </section>

        <section className='py-12 lg:pt-16 xl:pt-32 xl:pb-24 w-full relative overflow-hidden purple2tealBG'>
            <div className='max-w-7xl mx-auto lg:flex place-content-center xl:justify-start px-5 md:px-8 lg:px-0'>
                <div className='lg:w-7/12'>
                    <h2 className='homepage-heading text-purple-100 xl:w-10/12 text-center lg:text-left z-10 relative'>Curate your own custom Service Awards Catalog fulfilled by Amazon</h2>
                    <p className='xl:w-10/12 mt-7 mb-10 lg:my-10 text-purple-100 z-10 relative' style={{ lineHeight: '2rem' }}>Easily set up a customized gift catalog for work anniversaries and give your employees the flexibility to choose their preferred work anniversary gift across an array of products and get it conveniently delivered to their doorstep with Prime delivery services.</p>
                    <a className='vc-new-white-bg-btn-rounded-full text-gray-500 w-5/6 md:w-1/2 xl:w-4/12 mx-auto lg:mx-0 z-10 relative' href="#form">Get Started</a>
                </div>
                <div className='flex place-content-center lg:w-4/12 mt-9 xl:mt-0'>
                    <picture>
                        <source className='z-10 relative mx-auto transform xl:scale-125' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/2023/10/Amazon-amazone-sec.webp" media="(min-width: 1024px)" type="image/webp"alt="Vantage Rewards" width="680" />
                        <source className='z-10 relative mx-auto transform xl:scale-125' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/Amazon-amazone-sec.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="400" />
                        <img className='z-10 relative mx-auto transform xl:scale-125' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Amazon-amazone-sec.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Amazon-amazone-sec.webp" alt="Vantage Rewards" width="500" />
                    </picture>
                </div>
            </div>
            <div className='w-80 h-80 hidden lg:block absolute -bottom-40 right-1/2 border rounded-full z-0' style= {{ borderWidth: '35px', background:'transparent', borderColor: '#4F5789', clipPath: 'polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)' }}></div>
            <div className='hidden lg:block absolute -top-40 -right-56 2xl:-right-52 border rounded-full z-0' style= {{ borderWidth: '40px', background:'transparent', borderColor: '#4F5789', height:'45rem', width: '45rem' }}></div>
        </section>

        <section className='w-full py-10 xl:py-20 dashboardRnR mx-auto mt-0 md:rounded-t-xl'>
                <div className='w-11/12 xl:max-w-6xl 2xl:w-full mx-auto rounded-xl'>
                    <h2 class="homepage-heading py-3 text-center max-w-4xl mx-auto">Add<span class="text-orange"> Rewards & Recognition</span> to it, and see the wonders!</h2>
                    <p className='text-gray-500 py-3 text-center mx-auto w-11/12 lg:w-8/12'>Elevate your employee engagement efforts with our rewards and recognition platform, complemented by Amazon Business Integration.</p>
                    <ul className='px-3 pt-10 xl:py-16 grid md:grid-cols-2 xl:grid-cols-3 gap-16 xl:gap-10 w-full mx-auto justify-center'>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-instant-recognition.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-instant-recognition.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-instant-recognition.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Instant Recognition</h3>
                                <p className="text-base m-0">Encourage on-spot peer-to-peer recognition with our innovative platform.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-culture-of-appreciation.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-culture-of-appreciation.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-culture-of-appreciation.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Culture of Appreciation</h3>
                                <p className="text-base m-0">Cultivate a culture of appreciation with our award-winning R&R solutions.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-super-quick-integration.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/perks-exclusive-getmore.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/perks-exclusive-getmore.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Super Quick Integration</h3>
                                <p className="text-base m-0">Seamless integration with your existing HCM/HRIS platform and single-sign-on(SSO) tools.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 lg:mt-3 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-language-support.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-language-support.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-language-support.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Language support</h3>
                                <p className="text-base m-0">Enhance global accessibility and inclusivity with our platform's support for 16+ languages.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 lg:mt-3 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-global-rewards-catalog.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-global-rewards-catalog.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-global-rewards-catalog.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Global Rewards Catalog</h3>
                                <p className="text-base m-0">Hassle-free rewards redemption experience available in 100+ countries & multiple currencies.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 lg:mt-3 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-multi-layered-analytics.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-multi-layered-analytics.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/10/amazon-multi-layered-analytics.webp" alt="Vantage Rewards" width="44" />
                                </picture>
                                <h3 className='pb-2'>Multi-layered analytics</h3>
                                <p className="text-base m-0">Intuitive dashboard to streamline your HR processes.</p>
                            </div>
                        </ul>
                </div>      
            </section>

            <section class="w-full flex place-content-center overflow-hidden py-10 xl:py-20 px-6 lg:px-0" id="PulseVC">
                <div class="lg:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-indigo-100 overflow-hidden rounded-xl px-0">
                    <div class="card-1 w-full md:mb-0 xl:mt-0 lg:w-full xl:w-2/3 2xl:w-2/3 overflow-hidden relative lg:flex">
                        <div className='lg:w-full grid place-content-center md:place-content-start'>    
                        <div className='bg-purple-100 flex place-content-center w-5/6 p-5 pb- md:pl-8 xl:p-7 xl:pl-12 rounded-br-3xl'  style={{  backgroundColor: "#40407a"}}>
                            <h2 className='homepage-heading text-purple-100'>Experience the <span className='font-bold' style={{ color: '#45ace9' }}>Prime</span> of benefits</h2>
                        </div>
                        {/* <h2 class="text-2xl md:text-4xl mx-auto md:mx-0 text-center md:text-left text-purple-100 pt-5 mb-2 xl:mb-2 lg:px-0 lg:w-10/12 xl:w-10/12 2xl:w-9/12">Get a <span className='text-orange'>free demo</span></h2> */}
                        {/* <p className='lg:w-11/12 mx-auto lg:mx-0 text-center md:text-left text-purple-100  p-7 pb-0 lg:pl-8 xl:p-7 xl:pl-12 m-0 pb-2'>See Vantage Perks in action</p> */}
                        <p className='text-purple-100 my-5 xl:my-0 pl-5 md:pl-8 xl:p-7 xl:pb-4 xl:pl-12 w-full md:w-4/6 m-0'>Unlock the Amazon advantage with our Rewards & Recognition platform.</p>
                        <ul className='flex flex-col w-full md:w-4/6 lg:w-5/6 xl:w-4/6 pl-5 md:pl-8 xl:p-3 xl:pl-12 mb-6 xl:mb-0'>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Freedom and flexibility to curate a digital Rewards Catalog</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Custom Milestone Catalog for Service Awards</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Intuitive Admin Dashboard for complete control</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>10M+ products from Amazon</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>User-friendly mobile and web apps</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Free shipping with Prime</p></div>
                            </li>
                        </ul>
                        </div>
                        <div className='flex md:w-full lg:w-1/3 justify-end items-end lg:hidden xl:block md:absolute right-5 md:right-0 lg:right-5 -bottom-0'>
                            <picture>
                                <source className="mt-3 transform scale-100 xl:scale-150 origin-bottom" loading="" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2023/10/amazon-CTA-banner.webp" type="image/webp" alt="Capterra" width="280" />
                                <img loading="" className='mt-3 transform scale-100 xl:scale-150 origin-bottom' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/amazon-CTA-banner.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2023/10/amazon-CTA-banner.webp" alt="Capterra" width="280" />
                            </picture>
                        </div>
                    </div>
                    <div class="card-1 rnrForm w-full xl:mt-0 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center px-6 py-7 lg:py-10 bg-indigo-50 bookBtnFix" id="form">
                        <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from fostering a dynamic culture of recognition</h2>
                        <div class="w-full flex justify-center items-center lato rewards  blackLabelForm team">
                            <div class="flex justify-center items-center">
                                <HubspotForm
                                portalId="6153052"
                                formId="8dd71346-84d4-43c7-bd98-0223464fb47f"
                                onSubmit={() => console.log("Submit!")}
                                onReady={form => console.log("Form ready!")}
                                loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </Layout>
  )
}

export default Amazon